<template>
  <div class="rc-view press pt-4">
    <v-container class="rc-padding fluid">
      <v-layout wrap row align-center justify-center>
        <v-flex xs12 md12 px-3 class="text-xs-center">
          <h1 class="rc-tituloSection">Prensa</h1>
          <h2 style="font-weight:normal">Mira donde hemos sido publicados</h2>
        </v-flex>
      </v-layout>
      <v-layout wrap row align-end justify-end>
        <v-flex xs12 md3 px3>
          <v-select outline class="border-text" item-id="text" item-value="year" :items="filter" :label="`Filtrar (${press.filter(el => filterValue != null ? el.year == filterValue : true).length})`" v-model="filterValue" @change="onChange()"></v-select>
        </v-flex>
      </v-layout>
      <v-layout wrap row align-start justify-start>
        <v-flex xs12>
          <v-layout wrap row>
            <v-flex xs12 sm6 md4 lg3 pa-3 v-for="(post,index) in historyList" :key="post + index">
              <PressCard :post="post" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout align-center justify-center align-content-center>
        <v-pagination class="mb-2 outshadow__rc" v-model="page" :length="pages" @input="updatePage" color="#FF8A00"></v-pagination>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import PressCard from '@/components/PressCard.vue'

@Component({
  metaInfo: {
    title: 'Prensa',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  components: {
    PressCard
  },
  data() {
    return{
      page: 1,
			pageSize: 12,
      listCount: 0,
			historyList: [],
      filterValue: null,
      filter: [
        {
          year: null,
          text: 'Todos',
        },
        {
          year: 2023,
          text: '2023',
        },
        {
          year: 2022,
          text: '2022',
        },
        {
          year: 2021,
          text: '2021',
        },
        {
          year: 2020,
          text: '2020',
        },
        {
          year: 2019,
          text: '2019',
        },
        {
          year: 2018,
          text: '2018',
        },
        {
          year: 2017,
          text: '2017',
        },
        {
          year: 2016,
          text: '2016',
        },
      ],
      press: [
        {
          url: 'https://open.spotify.com/episode/5gshx7SdMuchXp1XgEhqkL?si=zisf0ejbSPqBol5bmhZw7g&nd=1&_branch_match_id=1129226225977341201&utm_medium=sharing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXLy7IL8lMq9TLyczL1vdOdc%2FzSi%2FNqahMAgC5my6aIAAAAA%3D%3D',
          date: '28-03-2023',
          year: 2023,
          name: 'RedCapital, y cierre de tiendas Amazon GO en EEUU con Alex Tudor',
          image: '/images/logo-spotify.png',
        },
        {
          url: 'https://www.pauta.cl/programas/pauta-de-negocios/pauta-de-negocios-10-de-marzo-de-2023',
          date: '10-03-2023',
          year: 2023,
          name: 'Pauta de Negocios',
          image: '/images/logo-pauta.webp',
        },
        {
          url: 'https://www.diarioestrategia.cl/texto-diario/mostrar/4193898/pymes-deriva',
          date: '28-02-2023',
          year: 2023,
          name: 'Pymes a la deriva',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://opinion.cooperativa.cl/opinion/economia/las-pymes-a-la-deriva/2023-02-20/180528.html',
          date: '20-02-2023',
          year: 2023,
          name: 'Las pymes a la deriva',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.revistalogistec.com/logistica/rrhh-2/4750-los-riesgos-financieros-y-digitales-en-los-que-deben-enfocarse-las-pymes-para-un-exitoso-2023',
          date: '17-02-2023',
          year: 2023,
          name: 'Los riesgos financieros y digitales en los que deben enfocarse las pymes para un exitoso 2023',
          image: '/images/logo-logistec.jpeg',
        },
        {
          url: 'https://www.diarioestrategia.cl/texto-diario/mostrar/4177048/alternativas-pymes',
          date: '15-02-2023',
          year: 2023,
          name: 'Alternativas para las Pymes',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://www.diarioestrategia.cl/texto-diario/mostrar/4142878/redcapital-crecio-121-2022',
          date: '17-01-2023',
          year: 2023,
          name: 'RedCapital creció un 121% en 2022',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=mmcyy',
          date: '08-01-2023',
          year: 2023,
          name: 'Las claves para que tu pyme logre navegar con éxito en un complejo 2023',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=mikcm',
          date: '27-12-2022',
          year: 2022,
          name: 'Desafíos que trae el 2023 para las pymes y emprendimientos',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://opinion.cooperativa.cl/opinion/economia/pymes-y-un-complejo-2023/2022-12-26/154929.html',
          date: '26-12-2022',
          year: 2022,
          name: 'Pymes y un complejo 2023',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.t13.cl/noticia/emprendedores/pymes-cierre-ano-y-proyecciones-2023',
          date: '23-12-2022',
          year: 2022,
          name: 'Pymes: Cierre de año y proyecciones para el 2023',
          image: '/images/logo-13.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=mclyp',
          date: '12-12-2022',
          year: 2022,
          name: 'Entrevista a Gustavo Ananía, fundador de Red Capital',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://www.duna.cl/programa/informacion-privilegiada/2022/11/30/gustavo-anania-de-redcapital-y-su-analisis-de-la-industria-fintech-a-mediano-plazo/',
          date: '30-11-2022',
          year: 2022,
          name: 'Gustavo Ananía, de RedCapital, y su análisis de la industria fintech a mediano plazo',
          image: '/images/prensa/logo-duna.png',
        },
        {
          url: 'https://opinion.cooperativa.cl/opinion/economia/ley-de-finanzas-abiertas-voto-de-confianza/2022-11-03/124612.html',
          date: '03-11-2022',
          year: 2022,
          name: 'Ley de Finanzas Abiertas, voto de confianzas',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.emol.com/noticias/Economia/2022/09/27/1073988/60-financiamiento-liderados-por-mujeres.html',
          date: '11-10-2022',
          year: 2022,
          name: 'Más del 60% de los emprendimientos que obtienen financiamiento alternativo son liderados por mujeres',
          image: '/images/prensa/logo_emol.png',
        },
        {
          url: 'https://www.emol.com/noticias/Economia/2022/08/18/1070275/nuevas-opciones-de-financiamiento-pymes.html',
          date: '18-08-2022',
          year: 2022,
          name: 'Fintech nacional permitirá a las pymes recibir en 2023 más de US$ 1.000 millones en financiamiento',
          image: '/images/prensa/logo_emol.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=l0ajs',
          date: '10-08-2022',
          year: 2022,
          name: 'Ayuda para las pymes: el crecimiento del financiamiento vía crowdfunding en medio de la caída del de la banca',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://opinion.cooperativa.cl/opinion/economia/un-sernac-para-los-emprendedores/2022-08-08/124432.html',
          date: '08-08-2022',
          year: 2022,
          name: 'Un SERNAC para los emprendedores',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.latercera.com/opinion/noticia/ley-fintech-y-privacidad-de-datos/YUNV3CLNKRFFRDWMSY6J4NWFNU',
          date: '07-06-2022',
          year: 2022,
          name: 'Ley Fintech y privacidad de datos',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'https://www.radioagricultura.cl/podcast/desafio-digital-2-0-martes-31-mayo-2022/',
          date: '31-05-2022',
          year: 2022,
          name: 'RedCapital en Desafío Digital de RADIO AGRICULTURA',
          image: '/images/prensa/logo_agricultura.png',
        },
        {
          url: 'https://www.duna.cl/programa/informacion-privilegiada/2022/03/16/gustavo-anania-de-redcapital-cl-somos-una-plataforma-digital-y-chilena-de-financiamiento/',
          date: '16-03-2022',
          year: 2022,
          name: 'Gustavo Ananía, de RedCapital.cl: “Somos una plataforma digital y chilena, de financiamiento”',
          image: '/images/prensa/logo-duna.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=iwe8p',
          date: '01-02-2022',
          year: 2022,
          name: 'Acá le miden el estado financiero de su negocio',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=hccvs',
          date: '16-09-2021',
          year: 2021,
          name: '“El proyecto de ley de Innovación Financiera bajo la lupa de cuatro fintech chilenas”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://envivo.futuro.cl/audio/futuro_palabraqueesnoticia_20210914_070000_080000/',
          date: '14-09-2021',
          year: 2021,
          name: '“Vocería en Palabra Que Es Noticia. Entrevista a Gustavo Anania”',
          image: '/images/prensa/Futuro.png',
        },
        {
          url: 'https://www.infinita.cl/programas/dejate-caer/2021/09/13/dejate-caer-13-de-septiembre-2021.html',
          date: '13-09-2021',
          year: 2021,
          name: '“Vocería en Déjate Caer. Entrevista a Gustavo Anania”',
          image: '/images/prensa/Infinita.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=h6frd',
          date: '01-09-2021',
          year: 2021,
          name: '“Procedimientos concursales: un paso hacia la recuperación”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=gvac1',
          date: '20-08-2021',
          year: 2021,
          name: '“Apoyo a las MiPyme y empleo”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.duna.cl/podcasts/dominga-perros-redcapital-inclusion-financiera-y-bolsa-local-abre-a-la-baja/',
          date: '12-08-2021',
          year: 2021,
          name: '“Dominga, perros, RedCapital, inclusión financiera y bolsa local abre a la baja”',
          image: '/images/prensa/logo-duna.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=gxx47',
          date: '30-07-2021',
          year: 2021,
          name: '“Quest Capital entra como inversionista de la FinTech RedCapital en nueva ronda de capital”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=gwrbf',
          date: '26-07-2021',
          year: 2021,
          name: '“Las pymes en los programas de noviembre”',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=gj2ff',
          date: '02-06-2021',
          year: 2021,
          name: '“¿Alivio para las PYME?”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=g26kg',
          date: '06-04-2021',
          year: 2021,
          name: '“Emprender en 2021: Consejos para que los microempresarios comiencen con el pie derecho”',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'https://portal.nexnews.cl/showN?valor=fdxmk',
          date: '08-01-2021',
          year: 2021,
          name: '“Participación de mujeres en creación de empresas crece 140% en seis años”',
          image: '/images/prensa/logo_emol.png',
        },
        
        {
          url: 'https://soychile-cdn.ned.media/videotron/videos-largos/2020/09/1000001370_1600346356.mp4',
          date: '18-09-2020',
          year: 2020,
          name: '“Entrevista a Gustavo Ananía”',
          image: '/images/soytv.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/2089827/radar-pyme-foco-digital-principales-iniciativas-pro-pequena-mediana-empresa',
          date: '17-09-2020',
          year: 2020,
          name: '“​​Radar Pyme: foco digital en las principales iniciativas pro pequeña y mediana empresa”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=eo9yi',
          date: '10-09-2020',
          year: 2020,
          name: '“​RedCapital suma nueva unidad de negocio para evitar el doble pago de facturas”',
          image: '/images/df-logo.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/2082867/crowdfunding-como-funciona-financiamiento-colectivo',
          date: '10-09-2020',
          year: 2020,
          name: '“​Crowdfunding: ¿Cómo funciona este financiamiento colectivo?”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=enley',
          date: '08-09-2020',
          year: 2020,
          name: '“¿Conoce qué es y cómo opera el financiamiento colectivo?”',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'https://www.latercera.com/pulso/noticia/pymes-en-desconfinamiento-y-el-10-consejos-para-enfrentar-este-periodo/AIUH3YZS6ZE2XGA2OV64EAW24I/',
          date: '03-09-2020',
          year: 2020,
          name: '“Pymes en desconfinamiento y el 10%: consejos para enfrentar este periodo”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=ekcjj',
          date: '25-08-2020',
          year: 2020,
          name: '“La oportunidad del Fondo Crece”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/opinion/cartas/la-oportunidad-del-fondo-crece/2020-08-24/180617.html',
          date: '25-08-2020',
          year: 2020,
          name: '“La oportunidad del Fondo Crece”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://ellibero.cl/actualidad/redcapital-inversiones-con-impacto-social-rentan-un-12-promedio-anual/',
          date: '09-08-2020',
          year: 2020,
          name: '“RedCapital: «Inversiones con impacto social rentan un 12% promedio anual»”',
          image: '/images/ellibero.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=efaf7',
          date: '04-08-2020',
          year: 2020,
          name: '“​El “casting” de BancoEstado para modernizar el banco a través de las FinTech”',
          image: '/images/df-logo.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/2046002/crowdfunding-alternativa-quienes-deciden-retirar-10-afp-e-invertir',
          date: '31-07-2020',
          year: 2020,
          name: '“​Crowdfunding, una alternativa para quienes deciden retirar su 10% de las AFP e invertir”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=eejlj',
          date: '31-07-2020',
          year: 2020,
          name: '“Entes financieros activan asesorías y bancos crean cuentas especiales para recibir fondos”',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=ecwhb',
          date: '23-07-2020',
          year: 2020,
          name: '“Emprender y reinventarse: Cómo convertir tu negocio al delivery y no morir en el intento”',
          image: '/images/chv-logo.png',
        },
        {
          url: 'https://www.terra.cl/estilo-de-vida/2020/7/15/guia-para-las-pymes-el-camino-seguir-en-pandemia-653.html',
          date: '15-07-2020',
          year: 2020,
          name: '“​Guía para las Pymes: El camino a seguir en pandemia”',
          image: '/images/terra.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/2029913/transformacion-digital-pymes-nunca-tarde',
          date: '15-07-2020',
          year: 2020,
          name: '“​Transformación digital y Pymes: Nunca es tarde”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=eb3su',
          date: '12-07-2020',
          year: 2020,
          name: '“Nuevas pymes llegan a los factoring frente a mayor necesidad de liquidez inmediata”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=eaz45',
          date: '10-07-2020',
          year: 2020,
          name: '“STARTUPS navegando bajo pandemia”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'https://www.latercera.com/startup-bajo-pandemia-la-reconversion/',
          date: '10-07-2020',
          year: 2020,
          name: '“STARTUPS navegando bajo pandemia”',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'https://www.latercera.com/opinion/noticia/regulacion-y-el-rol-de-las-fintech-en-chile/F5PXD5YIH5GDLI3L57Z4BOY5UU/',
          date: '02-07-2020',
          year: 2020,
          name: '“Regulación y el rol de las Fintech en Chile”',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e8z5f',
          date: '28-06-2020',
          year: 2020,
          name: '“Entrevista en 4ta Edición - El Diario de Cooperativa”',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e8ye5',
          date: '27-06-2020',
          year: 2020,
          name: '“​Entrevista en 2da Edición - El Diario de Cooperativa”',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e8ygv',
          date: '27-06-2020',
          year: 2020,
          name: '“​Gremios estiman que cerca de 200 mil pymes desaparecerán tras la pandemia”',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e8wnd',
          date: '27-06-2020',
          year: 2020,
          name: '“​Día de la Pyme: las principales ayudas apuntan a proporcionar liquidez y mejorar ventas”',
          image: '/images/elmostrador.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/1997521/informe-radar-pyme-pandemia-principales-ayudas-apuntan-proporcionar-liquidez-mejorar-ventas',
          date: '19-06-2020',
          year: 2020,
          name: '“​Informe Radar Pyme en Pandemia: principales ayudas apuntan a proporcionar liquidez y mejorar ventas”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://www.biobiochile.cl/noticias/economia/negocios-y-empresas/2020/06/11/crowdfunding-una-alternativa-las-pymes-no-podido-acceder-beneficios-estatales.shtml',
          date: '11-06-2020',
          year: 2020,
          name: '“Crowdfunding: Una alternativa para las pymes que no han podido acceder a beneficios estatales”',
          image: '/images/bbcl-logo.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/1982431/atencion-pymes-5-consejos-entender-acceder-financiamiento-colectivo',
          date: '05-06-2020',
          year: 2020,
          name: '“​Atención Pymes: 5 consejos para entender y acceder al financiamiento colectivo”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://www.latercera.com/pulso/noticia/oportunidades-para-invertir-en-renta-fija-a-corto-plazo-y-con-excelentes-rentabilidades/2HJDJVYMSVDBPMFDKI4OG23ZEI/',
          date: '26-05-2020',
          year: 2020,
          name: '“Oportunidades para invertir en renta fija a corto plazo y con excelentes rentabilidades”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/redcapital-crece-30-en-primer-trimestre-pese-a-los-impactos-de-la-crisis/2020-05-25/091311.html',
          date: '25-05-2020',
          year: 2020,
          name: '“REDCAPITAL CRECE 30% EN PRIMER TRIMESTRE, PESE A LOS IMPACTOS DE LA CRISIS”',
          image: '/images/df-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e27k9',
          date: '25-05-2020',
          year: 2020,
          name: '“REDCAPITAL CRECE 30% EN PRIMER TRIMESTRE, PESE A LOS IMPACTOS DE LA CRISIS”',
          image: '/images/df-logo.png',
        },
        {
          url: 'http://www.diarioestrategia.cl/texto-diario/mostrar/1965117/efectos-covid-19-urgencia-liquidez',
          date: '22-05-2020',
          year: 2020,
          name: '“Efectos Covid-19 y urgencia liquidez”',
          image: '/images/diarioestrategia.png',
        },
        {
          url: 'https://ellibero.cl/carta/fintech-y-su-capacidad-de-otorgar-liquidez-a-las-pymes/',
          date: '21-05-2020',
          year: 2020,
          name: '“Fintech y su capacidad de otorgar liquidez a las Pymes”',
          image: '/images/ellibero.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e15p8',
          date: '19-05-2020',
          year: 2020,
          name: '“Corfo prevé llegar a 40.000 pymes con Fondo Crece que dará liquidez a entidades no bancarias”',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e15x4',
          date: '19-05-2020',
          year: 2020,
          name: '“Corfo prevé llegar a 40.000 pymes con Fondo Crece que dará liquidez a entidades no bancarias”',
          image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=e0h5t',
          date: '15-05-2020',
          year: 2020,
          name: '“La ayuda para las PYME no es suficiente”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/opinion/cartas/la-ayuda-para-las-pyme-no-es-suficiente/2020-05-14/182613.html',
          date: '15-05-2020',
          year: 2020,
          name: '“La ayuda para las PYME no es suficiente”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/df-lab/innovacion-y-startups/guia-de-financiamiento-no-bancario-para-pyme-en-el-contexto-de-coronavirus/2020-04-30/115538.html',
          date: '30-04-2020',
          year: 2020,
          name: '“Guía de financiamiento no bancarios para Pymes en contexto coronavirus”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.spreaker.com/user/fmconquistador/hdf-27-04-2020',
          date: '27-04-2020',
          year: 2020,
          name: '“Entrevista a Gustavo Ananía”',
          image: '/images/elconquistador.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=dwwlw',
          date: '27-04-2020',
          year: 2020,
          name: '“Fintech que dan financiamiento a pymes buscan liquidez: se duplica la demanda de créditos, pero hay rescates masivos en fondos del deuda privada”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=dwvse',
          date: '26-04-2020',
          year: 2020,
          name: '“Fintech que dan financiamiento a pymes buscan liquidez: se duplica la demanda de créditos, pero hay rescates masivos en fondos del deuda privada”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=dupft',
          date: '16-04-2020',
          year: 2020,
          name: '“La Invisibilidad de las FinTech”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/opinion/cartas/la-invisibilidad-de-las-fintech/2020-04-15/170951.html',
          date: '15-04-2020',
          year: 2020,
          name: '“La Invisibilidad de las FinTech”',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.chvnoticias.cl/bolsillo/pymes-apoyo-financiamiento-coronavirus_20200415/',
          date: '15-04-2020',
          year: 2020,
          name: '“Atención, pymes: Averigua acá cómo obtener financiamiento y apoyo en ventas durante la pandemia”',
          image: '/images/chv-logo.png',
        },
        {
          url: 'https://soundcloud.com/alexander-bracho-664841571/gustavo-anania-cooperativa12-de-abril2020',
          date: '12-04-2020',
          year: 2020,
          name: '“Entrevista a Gustavo Ananía (8 minutos)”',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.chvnoticias.cl/coronavirus/10-emprendimientos-verduras-carne-pan-casa_20200323/',
          date: '23-03-2020',
          year: 2020,
          name: '“Hasta la puerta de tu casa: 10 emprendimientos que llevan verduras, carne o pan a domicilio”',
          image: '/images/chv-logo.png',
        },
        {
          url: 'https://www.latercera.com/pulso/noticia/muy-preocupados-pero-optimistas-la-sensacion-de-las-startups-chilenas/O2Q64JFKVBF5JBXOPMT7YDOWOE/',
          date: '21-03-2020',
          year: 2020,
          name: '“Muy preocupados, pero optimistas: la sensación de las startups chilenas”',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=dpj6y',
          date: '21-03-2020',
          year: 2020,
          name: '“Muy preocupados, pero optimistas: la sensación de las startups chilenas”',
         image: '/images/prensa/la-tercera-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=doeqc',
          date: '16-03-2020',
          year: 2020,
          name: '“Tips para que pymes enfrenten la segunda quincena de marzo”',
          image: '/images/logoL4.png',
        },
        {
          url: 'https://mujeresymas.cl/pymes-5-consejos-para-enfrentar-marzo/',
          date: '10-03-2020',
          year: 2020,
          name: '“Pymes: 5 consejos para enfrentar marzo”',
           image: '/images/MyM.png',
        }, 
        {
          url: 'https://www.latercera.com/pulso/noticia/redcapital-crowdfunding-ya-mira-al-mercado-mexicano/730163/',
          date: '05-07-2019',
          year: 2019,
          name: 'RedCapital, el crowdfunding que ya mira al mercado mexicano',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=MTU3UzU1NTE1Sjc0NTg2NzM2ODY5Nzk0NTUwNDg1MDUxNTI1MjQ4NDk0NTY2NzE0NTQ4VzQ0NDQ0NDQ0NDQ0Mg==',
          date: '27-06-2019',
          year: 2019,
          name: 'Celebran el Día Mundial de las Pymes',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://www.cooperativa.cl/noticias/economia/empresas/como-impulsar-el-crecimiento-de-las-pymes/2019-06-26/060044.html',
          date: '26-06-2019',
          year: 2019,
          name: '¿Cómo impulsar el crecimiento de las pymes?',
          image: '/images/cooperativa-logo.png',
        },
        {
          url: 'https://www.chvnoticias.cl/bolsillo/redcapital-crowdfunding-pymes-chile_20190529/',
          date: '29-05-2019',
          year: 2019,
          name: 'RedCapital, la plataforma crowdfunding chilena que ayuda a financiar Pymes, se proyecta a toda América',
          image: '/images/chv-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/el-crowdfunding-fue-el-instrumento-de-inversion-mas-rentable-en-chile/2019-02-27/170834.html',
          date: '27-02-2019',
          year: 2019,
          name: '“El crowdfunding fue el instrumento de inversión más rentable en Chile el año pasado”',
          image: '/images/df-logo.png',
        },
        {
          url: 'http://portal.nexnews.cl/showN?valor=NTJINzc1NTZBOTI4NzI1NDAzNDU0MTgzNTQxMzY1NDIzMjUyMjc0NTI4Mjg1Mjg3NDUyNzc0MDI1MjI1MzMzNTUyMjc0MFE1NTU1NTU1NTU1NTU1',
          date: '29-10-2018',
          year: 2018,
          name: 'Las nuevas opciones para el financiamiento de las pymes',
          image: '/images/nexnews-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/gustavo-anania-ceo-de-redcapital-cl-la-gente-esta-dispuesta-a-invertir/2018-05-02/092739.html',
          date: '02-05-2018',
          year: 2018,
          name: 'Gustavo Ananía, CEO de RedCapital.cl: "la gente está dispuesta a invertir en las pymes"',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://pulsosocial.com/2018/04/12/celcoin-redcapital-payit-cuota-latina-seedstars-summit-competira-us500-k/',
          date: '12-04-2018',
          year: 2018,
          name: 'Redcapital, Celcoin y Payit, la cuota latina entre las finalistas por el Seedstars Global Winner',
          image: '/images/pulso-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/empresas/empresas-y-startups/redcapital-cl-unico-emprendimiento-chileno-que-concursa-por-us-1-millon/2018-04-11/163726.html',
          date: '11-04-2018',
          year: 2018,
          name: 'RedCapital.cl el único	emprendimiento Chileno que concursa por $US 1 millón en Suiza',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/redcapital-cl-potencia-negocio-de-crowdfunding-con-expansion-en-leasing/2017-08-28/164847.html',
          date: '28-08-2017',
          year: 2017,
          name: 'Redcapital.cl potencia negocio de crowdfunfing con expansión en leasing inmobiliario',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://www.df.cl/noticias/brandcorner/red-capital/crowdfunding-una-doble-oportunidad-de-credito-e-inversion/2017-08-09/195529.html',
          date: '09-08-2017',
          year: 2017,
          name: 'Crowdfunding: Una Doble oportunidad de crédito e inversión',
          image: '/images/df-logo.png',
        },
        {
          url: 'https://digital.elmercurio.com/2017/08/07/B/VV36QEFM',
          date: '07-08-2017',
          year: 2017,
          name: 'Cuando invertir en pymes se transforma en un buen negocio',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'https://digital.elmercurio.com/2017/08/21/content/pages/img/pdf/TC374R3P.pdf',
          date: '21-08-2017',
          year: 2017,
          name: 'Cambios tecnológicos incentivan a los inversionistas a realizar diversos negocios',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'http://www.economiaynegocios.cl/noticias/noticias.asp?id=388349',
          date: '14-08-2017',
          year: 2017,
          name: 'Con redcapital.cl cualquier inversionista puede "tener un factoring"',
          image: '/images/mercurio-logo.png',
        },
        {
          url: 'http://pyme.emol.com/8648/la-nueva-red-colaborativa-ya-ha-financiado-mas-100-pymes-chile/',
          date: '07-07-2017',
          year: 2017,
          name: '"La red colaborativa que ya ha financiado a más de 100 pymes en Chile"',
          image: '/images/emol-logo.png',
        },
        {
          url: 'http://www.cnnchile.com/noticia/2017/07/03/redcapital-personas-financian-pymes',
          date: '03-07-2017',
          year: 2017,
          name: '"Redcapital: Personas financian Pymes"',
          image: '/images/cnn-logo.png',
        },
        {
          url: 'https://www.forbes.com.mx/red-capital-se-llevo-maximo-reconocimiento-seedstars/amp/',
          date: '29-06-2017',
          year: 2017,
          name: '"Red Capital se llevo el máximo reconocimiento en el Seedstars"',
          image: '/images/forbes-logo.png',
        },
        {
          url: 'http://www.emol.com/noticias/Economia/2017/06/28/864667/RedCapital-se-adjudica-la-edicion-para-Chile-de-Seedstars-World.html',
          date: '28-06-2017',
          year: 2017,
          name: '"RedCapital se adjudica la edición para Chile de Seedstars World"',
          image: '/images/emol-logo.png',
        },
        {
          url: 'http://www.lun.com/Pages/NewsDetail.aspx?dt=2017-06-18&NewsID=375381&BodyID=0&PaginaId=31',
          date: '18-06-2017',
          year: 2017,
          name: '"RedCapital.cl Si no le pagan a tiempo, aquí tiene de dónde sacar plata"',
          image: '/images/lun-logo.png',
        },
        {
          url: 'https://www.capital.cl/negocios-b-la-red-del-millon/',
          date: '25-05-2017',
          year: 2017,
          name: '"RedCapital.cl Negocios B- la red del millón"',
          image: '/images/capital-logo.png',
        },
        {
          url: 'https://issuu.com/revistaaldamir/docs/aldamir_117_-_17abril_web',
          date: '17-04-2017',
          year: 2017,
          name: 'Gustavo Anania - Revista Aldamir - "Con nosotros cualquiera puede invertir"',
          image: '/images/aldamir-logo.png',
        },
        {
          url: 'http://www.fintechlatam.net/2016/12/27/entrevista-diario-financiero-gustavo-anania-ceo-de-redcapital/',
          date: '27-12-2016',
          year: 2016,
          name: 'Entrevista Diario Financiero: Gustavo Anania, CEO De Redcapital',
          image: '/images/df-logo.png',
        },
        // {
        //   url: 'http://ingenieria.udp.cl/conoce-la-nueva-plataforma-de-crowdfunding-del-egresado-de-ingenieria-civil-industrial-felipe-zanberk/',
        //   date: '2016-05-09',
        //   name: 'Conoce la nueva plataforma de crowdfunding del egresado de Ingeniería Civil Industrial Felipe Zanberk',
        //   image: 'https://www.redcapital.cl/img/udp.png',
        // },
      ],
    }
  },
  created() {
		this.initPage();
		this.updatePage(this.page);
	},
  methods: {
		initPage: function() {                
			this.listCount = this.press.filter(el => this.filterValue != null ? el.year == this.filterValue : true).length;

			if (this.listCount < this.pageSize) {
        this.historyList = this.press.filter(el => this.filterValue != null ? el.year == this.filterValue : true);
			} else {
        this.historyList = this.press.filter(el => this.filterValue != null ? el.year == this.filterValue : true).slice(0, this.pageSize);
			}
		},
		updatePage: function(pageIndex) {
      let _start = (pageIndex - 1) * this.pageSize;
			let _end = pageIndex * this.pageSize;
			this.historyList = this.press.filter(el => this.filterValue != null ? el.year == this.filterValue : true).slice(_start, _end);
			this.page = pageIndex;
		},
    onChange: function(){
      this.page = 1;
    }
	},
	computed: {
		pages() {
      this.listCount = this.press.filter(el => this.filterValue != null ? el.year == this.filterValue : true).length;
      this.updatePage(this.page);
			if (this.pageSize == null || this.listCount == null) return 0;
			return Math.ceil(this.listCount / this.pageSize);
		}
	},
})
export default class Press extends Vue {}
</script>

<style lang="scss" scoped>
.line-effect {
  box-shadow: inset 0px -0.5em 0px 0px #fde6c3;
  padding: 0px 25px;
}
.btn-go-where-the-endermans-lives {
  display: flex;
  max-height: 36px;
}
.outshadow__rc.v-pagination{
  li > button.v-pagination__item{
    box-shadow: 0 0px 1px -2px rgb(0 0 0 / 20%), 0 0px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  }
}
</style>
